import {
  SelectOption,
  SUBSYSTEM_COLORS_MAP,
  SUBSYSTEM_DESCRIPTIONS_MAP,
  SUBSYSTEM_ICONS_MAP,
  SUBSYSTEM_NAMES_MAP,
  SubsystemCode,
  SUBSYSTEMS,
} from '@shared/util';

import {SUBSYSTEM_ROUTES_MAP} from './subsystem-routes-map';

export const SUBSYSTEMS_LIST: ReadonlyArray<
  SelectOption<SubsystemCode, {routePath: string; color: string; iconName: string; description: string}>
> = SUBSYSTEMS.map(subsystemCode => {
  return {
    id: subsystemCode,
    label: SUBSYSTEM_NAMES_MAP[subsystemCode],
    extra: {
      routePath: SUBSYSTEM_ROUTES_MAP[subsystemCode],
      color: SUBSYSTEM_COLORS_MAP[subsystemCode],
      iconName: SUBSYSTEM_ICONS_MAP[subsystemCode],
      description: SUBSYSTEM_DESCRIPTIONS_MAP[subsystemCode],
    },
  };
});
