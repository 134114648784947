import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {entryFormValueToCreateBody, entryFormValueToUpdateBody} from '@portfolio/util';
import {PortfolioApiService} from '@projects-api-v2/data-access';
import {ymAction} from '@shared/util';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {
  Create,
  CreateFailure,
  CreateSuccess,
  Update,
  UpdateFailure,
  UpdateSuccess,
  UploadImage,
  UploadImageFailure,
  UploadImageSuccess,
} from './actions';

export interface State {
  loading: boolean;
}

export const defaultState: State = {
  loading: false,
};

@Injectable()
@State<State>({
  name: 'portfolioEntryForm',
  defaults: defaultState,
})
export class PortfolioEntryFormState {
  private readonly portfolioApiService = inject(PortfolioApiService);
  private readonly alerts = inject(TuiAlertService);

  @Selector()
  static loading(state: State): boolean {
    return state.loading;
  }

  @Action(Create, {cancelUncompleted: true})
  create(ctx: StateContext<State>, payload: Create) {
    ctx.patchState({loading: true});

    return this.portfolioApiService.create(entryFormValueToCreateBody(payload.formValue)).pipe(
      switchMap(apiResponse => ctx.dispatch(new CreateSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new CreateFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new CreateFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(CreateSuccess)
  createSuccess(ctx: StateContext<State>, _payload: CreateSuccess) {
    ctx.patchState({loading: false});

    ymAction('ProjectsCreateCreatePortfolio', {id: _payload});

    return this.alerts.open(`Портфель успешно создан`, {
      label: 'Выполнено',
      appearance: 'success',
    });
  }

  @Action(CreateFailure)
  createFailure(ctx: StateContext<State>, payload: CreateFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }

  @Action(Update, {cancelUncompleted: true})
  update(ctx: StateContext<State>, payload: Update) {
    ctx.patchState({loading: true});

    return this.portfolioApiService.patch(entryFormValueToUpdateBody(payload.formValue), payload.groupId).pipe(
      switchMap(apiResponse => ctx.dispatch(new UpdateSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new UpdateFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new UpdateFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(UpdateSuccess)
  updateSuccess(ctx: StateContext<State>, payload: UpdateSuccess) {
    ctx.patchState({loading: false});

    return this.alerts.open(`Портфель «${payload.apiResponse.name}» успешно обновлён`, {
      label: 'Выполнено',
      appearance: 'success',
    });
  }

  @Action(UpdateFailure)
  updateFailure(ctx: StateContext<State>, payload: UpdateFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }

  @Action(UploadImage)
  uploadImage(ctx: StateContext<State>, payload: UploadImage) {
    return this.portfolioApiService.uploadImage(payload.file).pipe(
      switchMap(imageName => ctx.dispatch(new UploadImageSuccess(imageName, payload.file))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(
            new UploadImageFailure((error.error as any)?.title || 'Неизвестная ошибка', payload.file),
          );
        }

        if (error instanceof Error) {
          return ctx.dispatch(new UploadImageFailure(error.message, payload.file));
        }

        return EMPTY;
      }),
    );
  }
}
