@if (data.code) {
  <h2>{{ data.code }}</h2>
}
@if (data.title) {
  <h3>{{ data.title }}</h3>
}
@if (data.messages.length > 0 && data.messages[0] !== '') {
  @for (item of data.messages; track item) {
    <tui-notification size="m" appearance="error" class="tui-space_vertical-4">
      <div>
        <div class="message" [innerHTML]="item"></div>
      </div>
    </tui-notification>
    <button tuiButton [appearance]="'secondary'" [iconStart]="'@tui.copy'" [size]="'m'" (click)="copyToClipboard(item)">
      Скопировать детальный лог
    </button>
  }
}
@if (needHelp) {
  <div class="help">
    <ntc-info-btn />
  </div>
}
