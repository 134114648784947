import {EntryFormImageType, EntryFormValue} from '@project/util';
import {ProjectDto} from '@projects-api-v2/util';

export class Create {
  static readonly type = '[Project Entry Form] Create';
  constructor(public formValue: EntryFormValue) {}
}

export class CreateSuccess {
  static readonly type = '[Project Entry Form] Create Success';
  constructor(
    public apiResponse: string,
    public description: string,
  ) {}
}

export class CreateFailure {
  static readonly type = '[Project Entry Form] Create Failure';
  constructor(public error: string) {}
}

export class Update {
  static readonly type = '[Project Entry Form] Update';
  constructor(
    public groupId: string,
    public formValue: EntryFormValue,
  ) {}
}

export class UpdateSuccess {
  static readonly type = '[Project Entry Form] Update Success';
  constructor(public apiResponse: ProjectDto) {}
}

export class UpdateFailure {
  static readonly type = '[Project Entry Form] Update Failure';
  constructor(public error: string) {}
}

export class AddImage {
  static readonly type = '[Project Entry Form] Add Image';
  constructor(
    public image: File,
    public type: EntryFormImageType,
  ) {}
}

export class AddImageSuccess {
  static readonly type = '[Project Entry Form] Add Image Success';
  constructor(
    public imageName: string,
    public type: EntryFormImageType,
    public file: File,
  ) {}
}

export class AddImageFailure {
  static readonly type = '[Project Entry Form] Add Image Failure';
  constructor(
    public error: string,
    public type: EntryFormImageType,
    public file: File,
  ) {}
}
