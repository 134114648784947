import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  CreateSuccess as CreateGroupSuccess,
  UpdateSuccess as UpdateGroupSuccess,
} from '@portfolio/data-access/entry-form';
import {DeleteEntrySuccess} from '@portfolio/data-access/entry-view';
import {UpdateSuccess} from '@project/data-access/entry-form';
import {PortfolioApiService} from '@projects-api-v2/data-access';
import {PortfolioDto} from '@projects-api-v2/util';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {
  DeleteListEntry,
  DeleteListEntryFailure,
  DeleteListEntrySuccess,
  GetData,
  GetDataFailure,
  GetDataSuccess,
  InitView,
} from './actions';

export interface State {
  data: readonly PortfolioDto[] | null;
  error: string | null;
  loading: boolean;
}

export const defaultState: State = {
  data: null,
  error: null,
  loading: false,
};

@Injectable()
@State<State>({
  name: 'portfolioListView',
  defaults: defaultState,
})
export class PortfolioListViewState {
  private readonly portfolioApiService = inject(PortfolioApiService);
  private readonly alerts = inject(TuiAlertService);

  @Selector()
  static data(state: State) {
    return state.data;
  }

  @Selector()
  static loading(state: State) {
    return state.loading;
  }

  @Selector()
  static error(state: State) {
    return state.error;
  }

  @Action(InitView)
  initView(ctx: StateContext<State>) {
    const state = ctx.getState();

    if (state.data === null && state.loading !== true) {
      return ctx.dispatch(new GetData());
    }

    return EMPTY;
  }

  @Action([UpdateSuccess])
  onProjectUpdate(ctx: StateContext<State>, payload: UpdateSuccess) {
    const portfolios = ctx.getState().data;
    let portfolioIndex = -1;
    let projectIndex = -1;

    if (portfolios?.length > 0) {
      for (let i = 0; i < portfolios.length; i++) {
        for (let j = 0; j < portfolios[i].lastProjects.length; j++) {
          const project = portfolios[i].lastProjects[j];

          if (project.id === payload.apiResponse.id) {
            portfolioIndex = i;
            projectIndex = j;
            break;
          }
        }
      }
    }

    if (portfolioIndex !== -1 && projectIndex !== -1) {
      ctx.patchState({
        data: portfolios.map((portfolio, i) => {
          if (i !== portfolioIndex) {
            return portfolio;
          }

          return {
            ...portfolio,
            lastProjects: portfolio.lastProjects.map((project, j) => {
              if (j !== projectIndex) {
                return project;
              }

              return {
                ...project,
                previewImageUrl: payload.apiResponse.previewImageUrl,
              };
            }),
          };
        }),
      });
    }
  }

  @Action([CreateGroupSuccess, UpdateGroupSuccess])
  entryFormApiActionSuccess(ctx: StateContext<State>) {
    ctx.dispatch(new GetData());
  }

  @Action([DeleteListEntrySuccess, DeleteEntrySuccess])
  entryDeleteSuccess(ctx: StateContext<State>, {deletedEntry}: DeleteListEntrySuccess) {
    const curentStateData = ctx.getState().data;

    ctx.patchState({
      data: curentStateData.filter(entry => entry.id !== deletedEntry.id),
    });
  }

  @Action(GetData)
  getData(ctx: StateContext<State>) {
    // const filterValue = this.store.selectSnapshot(FilterState.filterValue);

    ctx.patchState({loading: true});

    return this.portfolioApiService.getAll(/** convertFilterValueToQueryParams(filterValue) */).pipe(
      switchMap(apiResponse => ctx.dispatch(new GetDataSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new GetDataFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new GetDataFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(GetDataSuccess)
  getDataSuccess(ctx: StateContext<State>, payload: GetDataSuccess) {
    ctx.patchState({
      data: payload.apiResponse,
      loading: false,
    });
  }

  @Action(GetDataFailure)
  getDataFailure(ctx: StateContext<State>, payload: GetDataFailure) {
    ctx.patchState({
      error: payload.error,
      loading: false,
    });
  }

  @Action(DeleteListEntry, {cancelUncompleted: true})
  deleteListEntry(ctx: StateContext<State>, {entry}: DeleteListEntry) {
    ctx.patchState({loading: true});

    return this.portfolioApiService.remove(entry.id).pipe(
      switchMap(() => ctx.dispatch(new DeleteListEntrySuccess(entry))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new DeleteListEntryFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new DeleteListEntryFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(DeleteListEntrySuccess)
  deleteListEntrySuccess(ctx: StateContext<State>, {deletedEntry}: DeleteListEntrySuccess) {
    ctx.patchState({loading: false});

    return this.alerts.open(`Портфель проектов «${deletedEntry.name}» успешно удалён.`, {
      label: 'Выполнено',
      appearance: 'success',
    });
  }

  @Action(DeleteListEntryFailure)
  deleteListEntryFailure(ctx: StateContext<State>, payload: DeleteListEntryFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }
}
