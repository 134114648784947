export function pluralRu(val: number): 1 | 2 | 3 | 4 | 5 {
  const i = Math.floor(Math.abs(val));
  const v = val.toString().replace(/^[^.]*\.?/, '').length;

  if (v === 0 && i % 10 === 1 && !(i % 100 === 11)) {
    return 1;
  }

  if (v === 0 && i % 10 === Math.floor(i % 10) && i % 10 >= 2 && i % 10 <= 4 && !(i % 100 >= 12 && i % 100 <= 14)) {
    return 3;
  }

  if (
    (v === 0 && i % 10 === 0) ||
    (v === 0 && i % 10 === Math.floor(i % 10) && i % 10 >= 5 && i % 10 <= 9) ||
    (v === 0 && i % 100 === Math.floor(i % 100) && i % 100 >= 11 && i % 100 <= 14)
  ) {
    return 4;
  }

  return 5;
}
