import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ArtefactsType, IterationType} from '@common/types';
import {API_URL_PREFIX, ReinforcementGetResourceType} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ReinforcementApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(
    generationVariantId: string,
    artefactsType: ArtefactsType,
    iterationType: IterationType = 'First',
  ): Observable<Blob> {
    const params: ReinforcementGetResourceType = {
      artefactsType,
      iterationType,
    };

    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Reinforcement/${generationVariantId}/resource`, {
      params: {...params},
      responseType: 'blob',
    });
  }
}
