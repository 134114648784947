import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TuiButton, TuiIcon, TuiLink} from '@taiga-ui/core';
import {TuiAvatar} from '@taiga-ui/kit';

@Component({
  selector: 'ntc-info-btn',
  standalone: true,
  imports: [TuiButton, TuiIcon, NgIf, TuiLink, TuiAvatar],
  templateUrl: './info-btn.component.html',
  styleUrl: './info-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBtnComponent {}
