import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  CreateSuccess as CreateGroupSuccess,
  UpdateSuccess as UpdateGroupSuccess,
} from '@project/data-access/entry-form';
import {DeleteEntrySuccess as DeleteEntryFromEntryViewSuccess} from '@project/data-access/entry-view';
import {ProjectApiService} from '@projects-api-v2/data-access';
import {ProjectDto} from '@projects-api-v2/util';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {
  DeleteEntry,
  DeleteEntryFailure,
  DeleteEntrySuccess,
  GetData,
  GetDataFailure,
  GetDataSuccess,
  InitView,
  RefreshData,
} from './actions';

export interface State {
  portfolioId: string | null;

  data: readonly ProjectDto[] | null;
  error: string | null;
  loading: boolean;
}

export const defaultState: State = {
  portfolioId: null,

  data: null,
  error: null,
  loading: false,
};

@Injectable()
@State<State>({
  name: 'projectCardsView',
  defaults: defaultState,
})
export class ProjectCardsViewState {
  private readonly projectApiService = inject(ProjectApiService);
  private readonly alerts = inject(TuiAlertService);

  @Selector()
  static data(state: State) {
    return state.data;
  }

  @Selector()
  static loading(state: State) {
    return state.loading;
  }

  @Selector()
  static error(state: State) {
    return state.error;
  }

  @Action(InitView)
  initView(ctx: StateContext<State>, payload: InitView) {
    const state = ctx.getState();

    if ((state.data === null && state.loading !== true) || state.portfolioId !== payload.portfolioId) {
      ctx.patchState({portfolioId: payload.portfolioId});

      return ctx.dispatch(new GetData());
    }

    return EMPTY;
  }

  @Action([RefreshData, DeleteEntrySuccess, DeleteEntryFromEntryViewSuccess, CreateGroupSuccess, UpdateGroupSuccess])
  refreshData(ctx: StateContext<State>) {
    return ctx.dispatch(new GetData());
  }

  @Action(GetData)
  getData(ctx: StateContext<State>) {
    const state = ctx.getState();

    ctx.patchState({loading: true});

    return this.projectApiService.getAll({portfolioId: state.portfolioId}).pipe(
      switchMap(apiResponse => ctx.dispatch(new GetDataSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new GetDataFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new GetDataFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(GetDataSuccess)
  getDataSuccess(ctx: StateContext<State>, payload: GetDataSuccess) {
    ctx.patchState({
      data: payload.apiResponse,
      loading: false,
    });
  }

  @Action(GetDataFailure)
  getDataFailure(ctx: StateContext<State>, payload: GetDataFailure) {
    ctx.patchState({
      error: payload.error,
      loading: false,
    });
  }

  @Action(DeleteEntry, {cancelUncompleted: true})
  deleteListEntry(ctx: StateContext<State>, {entry}: DeleteEntry) {
    ctx.patchState({loading: true});

    return this.projectApiService.remove(entry.id).pipe(
      switchMap(() => ctx.dispatch(new DeleteEntrySuccess(entry))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new DeleteEntryFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new DeleteEntryFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(DeleteEntrySuccess)
  deleteListEntrySuccess(ctx: StateContext<State>, {deletedEntry}: DeleteEntrySuccess) {
    ctx.patchState({loading: false});

    return this.alerts.open(`Портфель проектов «${deletedEntry.name}» успешно удалён.`, {
      label: 'Выполнено',
      appearance: 'success',
    });
  }

  @Action(DeleteEntryFailure)
  deleteListEntryFailure(ctx: StateContext<State>, payload: DeleteEntryFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }
}
