<div>
  <img ngSrc="./assets/read-only/logo.svg" alt="Платформа. Научно-технологический-центр" height="32" width="375" />
</div>

<div class="content">
  <img ngSrc="./assets/read-only/auth-img.svg" alt="Check" height="140" width="140" />
  <div class="title">Вы успешно зарегистрированы</div>
  <div class="text">Свяжитесь с командой Платформы, чтобы получить доступ</div>
  <button appearance="flat" tuiButton type="button" class="tui-space_right-3" (click)="logOut()">К авторизации</button>
</div>
