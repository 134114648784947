import {AsyncPipe, NgClass, NgIf, NgTemplateOutlet} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterLink} from '@angular/router';
import {ProjectBreadcrumbsComponent} from '@project/feature/breadcrumbs';
import {TuiIcon, TuiScrollbar} from '@taiga-ui/core';

import {SubsystemBreadcrumbsComponent, SubsystemBreadcrumbsLinkComponent} from './components';
import {SubsystemBarComponent} from './subsystem-bar.component';

@NgModule({
  declarations: [SubsystemBarComponent],
  exports: [SubsystemBarComponent],
  imports: [
    // Angular
    NgIf,
    NgClass,
    RouterLink,
    AsyncPipe,
    NgTemplateOutlet,

    // Taiga
    TuiIcon,
    TuiScrollbar,

    // Custom
    ProjectBreadcrumbsComponent,
    SubsystemBreadcrumbsComponent,
    SubsystemBreadcrumbsLinkComponent,
  ],
})
export class SubsystemBarModule {}
