import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTES} from '@common/consts';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {map} from 'rxjs/operators';

export const roleGuard = () => {
  const oidcSecurityService = inject(OidcSecurityService);
  const router = inject(Router);

  return oidcSecurityService.getPayloadFromAccessToken().pipe(
    map((decodedToken: any) => {
      const platformaRoles = decodedToken?.resource_access?.platforma?.roles || [];

      if (platformaRoles.includes('user')) {
        return true;
      }

      router.navigate([ROUTES.readOnly]);

      return false;
    }),
  );
};
