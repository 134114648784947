/**
 * @todo Subsystem следует отделить от понятия GenerationTask
 */

export enum GenerationTaskSubsystemType {
  Layout = 'Layout',
  LayoutSections = 'LayoutSections',
  Structure = 'Structure',
  Structure2 = 'Structure2',
  HVAC = 'HVAC',
  HVAC2 = 'HVAC2',
  Plumbing = 'Plumbing',
  Plumbing2 = 'Plumbing2',
  Electrical = 'Electrical',
  Electrical2 = 'Electrical2',
  Mesh = 'Mesh',
  SpacePlanning = 'SpacePlanning',
  OMAR = 'OMAR',
  OMAR2 = 'OMAR2',
  Final = 'Final',
}

export const GenerationTaskSubsystemName: {
  [key in GenerationTaskSubsystemType]: string;
} = {
  [GenerationTaskSubsystemType.Layout]: 'КЗ',
  [GenerationTaskSubsystemType.LayoutSections]: 'КЗ',
  [GenerationTaskSubsystemType.Structure]: 'КР',
  [GenerationTaskSubsystemType.Structure2]: 'КР 2',
  [GenerationTaskSubsystemType.Mesh]: 'КР',
  [GenerationTaskSubsystemType.HVAC]: 'ОВиК',
  [GenerationTaskSubsystemType.HVAC2]: 'ОВиК 2',
  [GenerationTaskSubsystemType.Plumbing]: 'ВК',
  [GenerationTaskSubsystemType.Plumbing2]: 'ВК 2',
  [GenerationTaskSubsystemType.Electrical]: 'ЭОМ',
  [GenerationTaskSubsystemType.Electrical2]: 'ЭОМ 2',
  [GenerationTaskSubsystemType.SpacePlanning]: 'ОПР',
  [GenerationTaskSubsystemType.OMAR]: 'АР',
  [GenerationTaskSubsystemType.OMAR2]: 'АР 2',
  [GenerationTaskSubsystemType.Final]: 'Финал',
};

export const GenerationTaskSubsystemNameTogether: {
  [key in GenerationTaskSubsystemType]: string;
} = {
  [GenerationTaskSubsystemType.Layout]: 'КЗ',
  [GenerationTaskSubsystemType.LayoutSections]: 'КЗ',
  [GenerationTaskSubsystemType.Structure]: 'КР',
  [GenerationTaskSubsystemType.Structure2]: 'КР_2',
  [GenerationTaskSubsystemType.Mesh]: 'КР',
  [GenerationTaskSubsystemType.HVAC]: 'ОВиК',
  [GenerationTaskSubsystemType.HVAC2]: 'ОВиК_2',
  [GenerationTaskSubsystemType.Plumbing]: 'ВК',
  [GenerationTaskSubsystemType.Plumbing2]: 'ВК_2',
  [GenerationTaskSubsystemType.Electrical]: 'ЭОМ',
  [GenerationTaskSubsystemType.Electrical2]: 'ЭОМ_2',
  [GenerationTaskSubsystemType.SpacePlanning]: 'ОПР',
  [GenerationTaskSubsystemType.OMAR]: 'АР',
  [GenerationTaskSubsystemType.OMAR2]: 'АР_2',
  [GenerationTaskSubsystemType.Final]: 'Финал',
};

export const GenerationTaskSubsystemIcon: {
  [key in GenerationTaskSubsystemType]: string;
} = {
  [GenerationTaskSubsystemType.Layout]: '@tui.table',
  [GenerationTaskSubsystemType.LayoutSections]: '@tui.table',
  [GenerationTaskSubsystemType.Structure]: '@tui.box',
  [GenerationTaskSubsystemType.Structure2]: '@tui.box',
  [GenerationTaskSubsystemType.Mesh]: '@tui.box',
  [GenerationTaskSubsystemType.HVAC]: '@tui.wind',
  [GenerationTaskSubsystemType.HVAC2]: '@tui.wind',
  [GenerationTaskSubsystemType.Plumbing]: '@tui.droplet',
  [GenerationTaskSubsystemType.Plumbing2]: '@tui.droplet',
  [GenerationTaskSubsystemType.Electrical]: '@tui.zap',
  [GenerationTaskSubsystemType.Electrical2]: '@tui.zap',
  [GenerationTaskSubsystemType.SpacePlanning]: '@tui.layers',
  [GenerationTaskSubsystemType.OMAR]: '@tui.codesandbox',
  [GenerationTaskSubsystemType.OMAR2]: '@tui.codesandbox',
  [GenerationTaskSubsystemType.Final]: '@tui.command',
};
