import {SubsystemCode} from '../types';

/** Список всех кодов подсистем отсортированный по порядку следования в роботе */
export const SUBSYSTEMS: readonly SubsystemCode[] = [
  'Layout',
  'SpacePlanning',
  'OMAR',
  'Structure',
  'OMAR2',
  'Plumbing',
  'Plumbing2',
  'HVAC',
  'HVAC2',
  'Electrical',
  'Electrical2',
  'Structure2',
  /** @todo Что это?  */
  // 'Mesh',
];
