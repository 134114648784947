import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {PortfolioApiService} from '@projects-api-v2/data-access';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {DeleteEntry, DeleteEntryFailure, DeleteEntrySuccess} from './actions';

export interface State {}
export const defaultState: State = {};

@Injectable()
@State<State>({
  name: 'portfolioEntryView',
  defaults: defaultState,
})
export class PortfolioEntryViewState {
  private readonly portfolioApiService = inject(PortfolioApiService);
  private readonly alerts = inject(TuiAlertService);

  @Action(DeleteEntry, {cancelUncompleted: true})
  deleteListEntry(ctx: StateContext<State>, {entry}: DeleteEntry) {
    ctx.patchState({loading: true});

    return this.portfolioApiService.remove(entry.id).pipe(
      switchMap(() => ctx.dispatch(new DeleteEntrySuccess(entry))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new DeleteEntryFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new DeleteEntryFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(DeleteEntrySuccess)
  deleteListEntrySuccess(ctx: StateContext<State>, {deletedEntry}: DeleteEntrySuccess) {
    ctx.patchState({loading: false});

    return this.alerts.open(`Портфель проектов «${deletedEntry.name}» успешно удалён.`, {
      label: 'Выполнено',
      appearance: 'success',
    });
  }

  @Action(DeleteEntryFailure)
  deleteListEntryFailure(ctx: StateContext<State>, payload: DeleteEntryFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }
}
