import {Data, Params, RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngxs/router-plugin';

export interface ExtendedRouterState {
  url: string;
  data: Data;
  params: Params;
  pathParams: Params;
  queryParams: Params;
}

export class CustomRouterStateSerializer implements RouterStateSerializer<ExtendedRouterState> {
  serialize(routerState: RouterStateSnapshot): ExtendedRouterState {
    const {
      url,
      root: {queryParams},
    } = routerState;

    let route = routerState.root;
    let pathParams: Params = {...route.params};
    let data: Data = {...route.data};

    while (route.firstChild) {
      route = route.firstChild;
      pathParams = {...pathParams, ...route.params};
      data = {...data, ...route.data};
    }

    const {params} = route;

    return {url, params, data, queryParams, pathParams};
  }
}
