@for (crumb of crumbs$ | async; track crumb.label; let isLast = $last) {
  @if (!isLast) {
    <a class="link" [routerLink]="[crumb.pathFromRoot]">
      @if (crumb.iconName !== null) {
        <tui-icon class="link-icon" [icon]="crumb.iconName"></tui-icon>
      }

      @if (crumb.label !== null) {
        <tui-line-clamp class="label" [content]="crumb.label" [linesLimit]="1" [lineHeight]="20"></tui-line-clamp>
      }
    </a>
  }

  @if (isLast) {
    <span class="current">
      @if (crumb.label !== null) {
        <tui-line-clamp class="label" [content]="crumb.label" [linesLimit]="1" [lineHeight]="20"></tui-line-clamp>
      }
    </span>
  }

  @if (!isLast) {
    <span class="divider">/</span>
  }
}
