import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {RouterModule} from '@angular/router';
import {environment} from '@env/environment';
import {TuiIcon} from '@taiga-ui/core';

@Component({
  standalone: true,
  imports: [NgIf, RouterModule, TuiIcon],
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavComponent {
  @HostBinding('class._expanded')
  @Input()
  expanded = false;

  readonly stage = environment.isProduction ? '' : 'stage.';
}
