import {Inject, Injectable, Injector} from '@angular/core';
import {TuiDialogService} from '@taiga-ui/core';
import {TUI_CONFIRM, TuiConfirmData} from '@taiga-ui/kit';
import {PolymorpheusComponent} from '@taiga-ui/polymorpheus';
import {take} from 'rxjs';

import {ErrorModalComponent} from './components';
import {IErrorModal} from './components/error-modal/error-modal.interface';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) {}

  error(data: IErrorModal): void {
    this.dialogs
      .open<number>(new PolymorpheusComponent(ErrorModalComponent, this.injector), {
        data,
      })
      .subscribe();
  }

  confirm(confirmData: TuiConfirmData): Promise<boolean> {
    const data: TuiConfirmData = {
      content: confirmData.content,
      yes: confirmData.yes ?? 'Да',
      no: confirmData.no ?? 'Нет',
    };

    return new Promise(resolve => {
      this.dialogs
        .open<boolean>(TUI_CONFIRM, {
          size: 's',
          data,
        })
        .pipe(take(1))
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }
}
