import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {
  API_URL_PREFIX,
  HvacAirVentType,
  HvacBalancingValvePosition,
  HvacConfigDto,
  HvacFireResistanceLimit,
  HvacGetResourceParamsType,
  HvacInputUnit,
  HvacResourceType,
  HvacSmokeVentilationSharedAreaCalculationVariant,
} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HvacApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getResource(generationVariantId: string, dataType: HvacResourceType): Observable<Blob> {
    const params: HvacGetResourceParamsType = {
      dataType,
    };

    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Hvac/${generationVariantId}/resource`, {
      params: {
        ...params,
      },
      responseType: 'blob',
    });
  }

  getSettings(): Observable<HvacConfigDto> {
    const mockData = {
      smokeVentilationSharedAreas: {
        calculationVariant: 'V1' as HvacSmokeVentilationSharedAreaCalculationVariant,
        smokeExhaustAirDuctStandardSpeed: 11,
        compensationAirDuctStandardSpeed: 10,
        smokeExhaustValveStandardSpeed: 20,
        compensationValveStandardSpeed: 15,
        betweenSubZoneSideEdgeAndAirDuct: 50,
        betweenSubZoneSideEdgeAndSmokeExhaustAirDuctForActuator: 250,
        betweenSubZoneBackEdgeAndAirDuct: 50,
        betweenEngineeringOpeningEdgeAndAirDuct: 50,
        smokeExhaustLowering: 50,
      },
      typicalStoreyRoomHeatLoss: {
        apartmentFacadeWallHeatTransferCoefficient: 0.335,
        lluFacadeWallHeatTransferCoefficient: 0.335,
        windowHeatTransferCoefficient: 1.524,
        interApartmentDoorHeatTransferCoefficient: 1.261,
        interRoomDoorHeatTransferCoefficient: 1.261,
        innerWallHeatTransferCoefficient: 1.261,
        floorHeatTransferCoefficient: 1.261,
        ceilingHeatTransferCoefficient: 1.261,
        ceilingLLUHeatTransferCoefficient: 1.261,
      },
      ductInsulations: {
        verticalDuctApartmentVentilation: {
          thickness: 5,
          fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
        },
        horizontalDuctApartmentVentilationFromValveToWall: {
          thickness: 5,
          fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
        },
        verticalDuctInsideEngineeringZoneinSharedAreas: {
          thickness: 5,
          fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
        },
        smokeVentilationDuctsFromValveToFan: {
          thickness: 5,
          fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
        },
        fireSafeZoneDucts: {
          thickness: 5,
          fireResistanceLimit: 'EI60' as HvacFireResistanceLimit,
        },
      },
      ductInsets: {
        insetSizeAddWithWallValve: 95,
        roundDuctInsetInsertionLength: 55,
        roundDuctInsetLength: 20,
        rectangleInsetStandardLength: 100,
        rectangleInsetMinimalLength: 50,
      },
      heatingCollectors: {
        inputUnitType: 'None' as HvacInputUnit,
        airVentType: 'MaevskiTap' as HvacAirVentType,
        balancingValvePosition: 'Standalone' as HvacBalancingValvePosition,
      },
    };

    return of(mockData);
  }
}
