import {ProjectPatchBodyDto} from '@projects-api-v2/util';

import {EntryFormValue} from '../../types';

// eslint-disable-next-line max-statements, complexity
export function entryFormValueToUpdateBody(formValue: EntryFormValue): ProjectPatchBodyDto {
  const body: ProjectPatchBodyDto = {
    name: formValue.name,
    portfolioId: formValue.portfolioId,
    description: formValue.description,
    country: formValue.country,
    region: formValue.region,
    city: formValue.city,
    postalCode: formValue.postalCode,
    rawAddress: formValue.rawAddress,
    latitude: formValue.latitude,
    longitude: formValue.longitude,
    altitude: formValue.altitude,
    streetName: formValue.streetName,
    rotationAngle: formValue.rotationAngle,
    buildingType: formValue.buildingType.id,
    previewImageName: formValue.previewImageName,
    middleImageName: formValue.middleImageName,
    largeImageName: formValue.largeImageName,
  };

  if (formValue.name && formValue.name.trim().length > 0) {
    body.name = formValue.name;
  }

  if (formValue.portfolioId && formValue.portfolioId.trim().length > 0) {
    body.portfolioId = formValue.portfolioId;
  }

  if (formValue.description && formValue.description.trim().length > 0) {
    body.description = formValue.description;
  }

  if (formValue.country && formValue.country.trim().length > 0) {
    body.country = formValue.country;
  }

  if (formValue.region && formValue.region.trim().length > 0) {
    body.region = formValue.region;
  }

  if (formValue.city && formValue.city.trim().length > 0) {
    body.city = formValue.city;
  }

  if (formValue.postalCode && formValue.postalCode.trim().length > 0) {
    body.postalCode = formValue.postalCode;
  }

  if (formValue.rawAddress && formValue.rawAddress.trim().length > 0) {
    body.rawAddress = formValue.rawAddress;
  }

  if (formValue.latitude) {
    body.latitude = formValue.latitude;
  }

  if (formValue.longitude) {
    body.longitude = formValue.longitude;
  }

  if (formValue.altitude) {
    body.altitude = formValue.altitude;
  }

  if (formValue.streetName && formValue.streetName.trim().length > 0) {
    body.streetName = formValue.streetName;
  }

  if (formValue.rotationAngle) {
    body.rotationAngle = formValue.rotationAngle;
  }

  if (formValue.buildingType && formValue.buildingType.id.trim().length > 0) {
    body.buildingType = formValue.buildingType.id;
  }

  return body;
}
