import {PortfolioPatchBodyDto} from '@projects-api-v2/util';

import {EntryFormValue} from '../../types';

export function entryFormValueToUpdateBody(formValue: EntryFormValue): PortfolioPatchBodyDto {
  const body: PortfolioPatchBodyDto = {
    type: 'ResidentialComplex',
    imageName: formValue.imageName,
  };

  if (formValue.name && formValue.name.trim().length > 0) {
    body.name = formValue.name;
  }

  if (formValue.city && formValue.city.trim().length > 0) {
    body.city = formValue.city;
  }

  if (formValue.description && formValue.description.trim().length > 0) {
    body.description = formValue.description;
  }

  return body;
}
