import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {LayoutPresetAxisDto, LayoutPresetDto} from '@generation-api-v2/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LayoutApiService {
  private readonly http = inject(HttpClient);

  getPresets(): Observable<readonly LayoutPresetDto[]> {
    return this.http.get<readonly LayoutPresetDto[]>('/assets/mocks/layout-task-presets.json');
  }

  getAxisPresets(): Observable<readonly LayoutPresetAxisDto[]> {
    return this.http.get<readonly LayoutPresetAxisDto[]>('/assets/mocks/layout-axis-presets.json');
  }
}
