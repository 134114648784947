import {importProvidersFrom} from '@angular/core';
import {Routes} from '@angular/router';
import {AuthCallbackComponent} from '@auth/feature/auth-callback-view';
import {ReadOnlyPageComponent} from '@common/components/read-only-page/read-only-page.component';
import {roleGuard} from '@common/guards/role.guard';
import {CompareProjectViewState} from '@compare/data-access/project-view';
import {LayoutDefaultComponent} from '@layout/feature/default';
import {NgxsModule} from '@ngxs/store';
import {ProjectBreadcrumbsState} from '@project/data-access/breadcrumbs';
import {PageNotFoundComponent} from '@views/page-not-found/page-not-found.component';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';

export const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    providers: [
      importProvidersFrom(NgxsModule.forFeature([ProjectBreadcrumbsState])),
      importProvidersFrom(NgxsModule.forFeature([CompareProjectViewState])),
    ],
    children: [
      {
        path: '',
        redirectTo: 'portfolio',
        pathMatch: 'full',
      },
      {
        path: 'portfolio',
        data: {breadcrumb: 'Портфели проектов'},
        loadChildren: () => import('@portfolio/feature/shell').then(c => c.routes),
      },
      {
        path: 'references',
        data: {breadcrumb: 'Группы справочников'},
        loadChildren: () => import('@catalog-group/feature/shell').then(c => c.routes),
      },
      {
        path: 'organizations',
        data: {breadcrumb: 'Организации'},
        loadChildren: () => import('@organizations/feature/shell').then(c => c.routes),
      },
      {
        path: 'fc8aab6e-a17e-444f-b681-edb36ed2e272',
        data: {breadcrumb: 'Доступ'},
        loadChildren: () => import('@local-permission/feature/shell').then(c => c.routes),
      },
    ],
  },
  {
    path: 'build-info',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    data: {breadcrumb: 'Build info'},
    loadChildren: () => import('@build-info/feature/shell').then(c => c.routes),
  },
  {
    path: 'page-ifc-viewer',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () => import('@views/page-ifc-viewer/page-ifc-viewer.module').then(m => m.PageIfcViewerModule),
  },
  {
    path: 'structure-viewer',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () => import('@views/structure-viewer/structure-viewer.module').then(m => m.StructureViewerModule),
  },
  {
    path: 'diagnostic-structure-viewer',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () =>
      import('@views/diagnostic-structure-viewer/diagnostic-structure-viewer.module').then(
        m => m.DiagnosticStructureViewerModule,
      ),
  },
  {
    path: 'slot',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    loadChildren: () => import('@views/slot/slot.module').then(m => m.SlotModule),
  },
  {path: 'read-only', component: ReadOnlyPageComponent},
  {
    path: '**',
    canActivate: [AutoLoginPartialRoutesGuard, roleGuard],
    component: PageNotFoundComponent,
  },
];
