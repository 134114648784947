import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {InfoBtnComponent} from '@common/components/modals/components/info-btn/info-btn.component';
import {TuiButton, TuiGroup, TuiIcon, TuiLoader, TuiNotification} from '@taiga-ui/core';

import {ErrorModalComponent} from './components';

@NgModule({
  declarations: [ErrorModalComponent],
  imports: [
    // Angular
    CommonModule,

    // Taiga
    TuiButton,
    TuiNotification,
    TuiGroup,
    TuiLoader,
    TuiIcon,

    // Custom
    InfoBtnComponent,
  ],
})
export class ModalsModule {}
