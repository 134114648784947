import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ProjectApiService} from '@projects-api-v2/data-access';
import {TuiAlertService} from '@taiga-ui/core';
import {catchError, EMPTY, switchMap} from 'rxjs';

import {Update, UpdateFailure, UpdateSuccess} from './actions';

export interface State {
  loading: boolean;
}

export const defaultState: State = {
  loading: false,
};

@Injectable()
@State<State>({
  name: 'projectRotationForm',
  defaults: defaultState,
})
export class ProjectRotationFormState {
  private readonly projectApiService = inject(ProjectApiService);
  private readonly alerts = inject(TuiAlertService);

  @Selector()
  static loading(state: State): boolean {
    return state.loading;
  }

  @Action(Update, {cancelUncompleted: true})
  update(ctx: StateContext<State>, payload: Update) {
    ctx.patchState({loading: true});

    return this.projectApiService.patch(payload.body, payload.projectId).pipe(
      switchMap(apiResponse => ctx.dispatch(new UpdateSuccess(apiResponse))),
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          return ctx.dispatch(new UpdateFailure((error.error as any)?.title || 'Неизвестная ошибка'));
        }

        if (error instanceof Error) {
          return ctx.dispatch(new UpdateFailure(error.message));
        }

        return EMPTY;
      }),
    );
  }

  @Action(UpdateSuccess)
  updateSuccess(ctx: StateContext<State>, payload: UpdateSuccess) {
    ctx.patchState({loading: false});

    return this.alerts.open(`Проект «${payload.apiResponse.name}» успешно обновлён`, {
      label: 'Выполнено',
      appearance: 'success',
    });
  }

  @Action(UpdateFailure)
  updateFailure(ctx: StateContext<State>, payload: UpdateFailure) {
    ctx.patchState({loading: false});

    return this.alerts.open(payload.error, {
      label: 'Не выполнено',
      appearance: 'error',
    });
  }
}
