import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {convertBlobError} from '@common/operators';
import {API_URL_PREFIX, SketchPlan} from '@generation-api-v2/util';
import {GATEWAY_URL} from '@shared/util';
import {Observable, of, switchMap, timer} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OutputApiService {
  private readonly http = inject(HttpClient);
  private readonly gatewayUrl = inject(GATEWAY_URL);
  private readonly apiPrefix = inject(API_URL_PREFIX);

  getIfc(variantId: string): Observable<Blob> {
    return this.http.get(`${this.gatewayUrl}/${this.apiPrefix}/Output/${variantId}/ifc`, {
      responseType: 'blob',
    });
  }

  getStoreyPlanDxf(variantId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${variantId}/plan/dxf`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getStoreyPlanPdf(variantId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${variantId}/plan/pdf`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getSketchPlanDxf(generationTaskId: string, planTypeDto?: SketchPlan): Observable<Blob> {
    const params = planTypeDto ? {planTypeDto} : undefined;

    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationTaskId}/sketchPlan/dxf`, `"{}"`, {
      params,
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getSketchPlanPdf(generationTaskId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationTaskId}/sketchPlan/pdf`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getSketchbookDxf(generationTaskId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationTaskId}/sketchBook/dxf`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getSketchbookPdf(generationTaskId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${generationTaskId}/sketchBook/pdf`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getTeiPdf(variantId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${variantId}/tei/pdf`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  getTeiExcel(variantId: string): Observable<Blob> {
    return this.http.post(`${this.gatewayUrl}/${this.apiPrefix}/Output/${variantId}/tei/excel`, `"{}"`, {
      responseType: 'blob',
      headers: {'Content-type': 'application/json'},
    });
  }

  awaitIfcFileGeneration(variantId: string): Observable<Blob> {
    const getRequest$ = (): Observable<Blob> =>
      this.http
        .get(`${this.gatewayUrl}/${this.apiPrefix}/Output/${variantId}/ifc`, {
          observe: 'response',
          responseType: 'blob',
        })
        .pipe(
          switchMap(response => {
            if (response.status === 202 || response.status === 204) {
              return timer(3000).pipe(switchMap(getRequest$));
            }

            return of(response.body);
          }),
          convertBlobError(),
        );

    return getRequest$();
  }
}
