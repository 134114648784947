import {DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {ModalsModule} from '@common/components';
import {environment} from '@env/environment';
import {NgxsRouterPluginModule, RouterStateSerializer} from '@ngxs/router-plugin';
import {NgxsModule} from '@ngxs/store';
import {
  TUI_ASSETS_PATH,
  TUI_DEFAULT_NUMBER_FORMAT,
  TUI_ICON_RESOLVER,
  TUI_NUMBER_FORMAT,
  TuiAlert,
  TuiDialog,
  TuiIcon,
  TuiRoot,
} from '@taiga-ui/core';
import {NgDompurifySanitizer} from '@taiga-ui/dompurify';
import {NG_EVENT_PLUGINS} from '@taiga-ui/event-plugins';
import {TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE} from '@taiga-ui/i18n';
import {TuiPreview} from '@taiga-ui/kit';
import {TuiBlockStatus} from '@taiga-ui/layout';
import {TUI_SANITIZER} from '@taiga-ui/legacy';
import {AuthInterceptor, AuthModule} from 'angular-auth-oidc-client';
import {provideHighlightOptions} from 'ngx-highlightjs';
import {of} from 'rxjs';

import {AppComponent} from './app.component';
import {routes} from './routes';
import {CustomRouterStateSerializer} from './util';

registerLocaleData(localeRu);

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),

    // Taiga
    TuiRoot,
    TuiDialog,
    TuiAlert,
    ...TuiBlockStatus,
    ...TuiPreview,
    TuiIcon,

    // Vendor
    NgxsModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),

    // Custom
    ModalsModule,

    AuthModule.forRoot({
      config: {
        responseType: 'code',
        clientId: 'platforma',

        redirectUrl: `${window.location.origin}/auth-callback`,
        postLogoutRedirectUri: `${window.location.origin}`,

        authority: environment.authUrl,
        scope: 'openid',
        secureRoutes: [environment.apiUrl],
        useRefreshToken: true,
        silentRenew: true,
        silentRenewUrl: '/silent-renew.html',
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {provide: TUI_SANITIZER, useClass: NgDompurifySanitizer},
    {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: 'dd.MM.yyyy HH:mm',
      },
    },
    {provide: LOCALE_ID, useValue: 'ru'},
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_RUSSIAN_LANGUAGE),
    },
    {
      provide: TUI_NUMBER_FORMAT,
      useValue: of({
        ...TUI_DEFAULT_NUMBER_FORMAT,
        decimalSeparator: ',',
      }),
    },
    {
      provide: TUI_ICON_RESOLVER,
      deps: [TUI_ASSETS_PATH],
      useFactory: (path: string) => {
        return (icon: string) =>
          icon.startsWith('@tui.')
            ? `${path}/${icon.replace('@tui.', '').split('.').join('/')}.svg`
            : `/assets/icons/${icon}.svg`;
      },
    },
    provideAnimations(),
    provideHighlightOptions({
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      languages: {
        json: () => import('highlight.js/lib/languages/json'),
      },
    }),
    NG_EVENT_PLUGINS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
