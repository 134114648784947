import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  NgZone,
} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TuiActiveZone, tuiGetActualTarget, tuiIsElement, tuiTypedFromEvent, tuiZoneOptimized} from '@taiga-ui/cdk';
import {TuiScrollbar} from '@taiga-ui/core';
import {distinctUntilChanged, map, merge, share, tap} from 'rxjs';

import {AppLogoComponent} from '../app-logo';
import {AppNavComponent} from '../app-nav';
import {ProfileCardComponent} from '../profile-card';

@Component({
  standalone: true,
  imports: [RouterModule, TuiScrollbar, TuiActiveZone, AppNavComponent, AppLogoComponent, ProfileCardComponent],
  selector: 'app-navpanel',
  templateUrl: './app-navpanel.component.html',
  styleUrl: './app-navpanel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiActiveZone],
})
export class AppNavpanelComponent {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly document = inject(DOCUMENT);
  private readonly activeZone = inject(TuiActiveZone);
  private readonly currentElement = inject(ElementRef).nativeElement;
  private readonly stream$ = merge(
    tuiTypedFromEvent(this.document, 'mouseover').pipe(map(tuiGetActualTarget)),
    tuiTypedFromEvent(this.document, 'mouseout').pipe(map(e => e.relatedTarget)),
  ).pipe(
    map(element => tuiIsElement(element) && this.isHovered(element)),
    distinctUntilChanged(),
    tuiZoneOptimized(inject(NgZone)),
    tap(hovered => {
      this.hovered = hovered;
      this.cdr.markForCheck();
    }),
    share(),
  );

  @HostBinding('class._hovered')
  hovered = false;

  childZoneActive = false;

  constructor() {
    this.stream$.subscribe();
  }

  onChildActiveZone(childActive: boolean): void {
    this.childZoneActive = childActive;
  }

  /**
   * Метод для проверки позиции ховера с учетом внутреннего
   * дропдауна
   */
  private isHovered(element: Element): boolean {
    const host = this.currentElement;
    // Проверяем на уровне хоста
    const hovered = host.contains(element);
    // Проверяем детей внутри активированной зоны
    const child = !host.contains(element) && (this.activeZone.contains(element) || this.childZoneActive);

    return hovered || child;
  }
}
