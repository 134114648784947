import {SubsystemCode} from '../types';

export const SUBSYSTEM_DESCRIPTIONS_MAP: {[subsystemCode in SubsystemCode]: string} = {
  Layout: 'Компоновка здания',
  Structure: 'Конструктивные решения',
  Structure2: 'Конструктивные решения',
  HVAC: 'Отопление, вентиляция и кондиционирование',
  HVAC2: 'Отопление, вентиляция и кондиционирование',
  Plumbing: 'Водоснабжение и канализация',
  Plumbing2: 'Водоснабжение и канализация',
  Electrical: 'Освещение и силовое оборудование',
  Electrical2: 'Освещение и силовое оборудование',
  SpacePlanning: 'Объемно-планировочное решение',
  OMAR: 'Архитектурные решения',
  OMAR2: 'Архитектурные решения',
};
