// eslint-disable-next-line import/no-cycle
import {BUILDING_TYPES} from '@project/util';
import {ProjectDto} from '@projects-api-v2/util';

import {EntryFormValue} from '../../types';

export function entryDtoToFormValue(entry: ProjectDto): EntryFormValue {
  return {
    name: entry.name,
    portfolioId: entry.portfolioId,
    description: entry.description,
    country: entry.country,
    region: entry.region,
    city: entry.city,
    postalCode: entry.postalCode,
    rawAddress: entry.rawAddress,
    latitude: entry.latitude,
    longitude: entry.longitude,
    altitude: entry.altitude,
    streetName: entry.streetName,
    rotationAngle: entry.rotationAngle,
    buildingType: BUILDING_TYPES.find(buildingType => buildingType.id === entry.buildingType),
    previewImageName: entry.previewImageUrl?.replace('images/project/', '') || null,
    middleImageName: entry.middleImageUrl?.replace('images/project/', '') || null,
    largeImageName: entry.largeImageUrl?.replace('images/project/', '') || null,
  };
}
