import {SubsystemCode} from '@shared/util';

const STATE_NAME = '[Compare Project View]';

export class InitView {
  static readonly type = `${STATE_NAME} Init View`;
}

export class DestroyView {
  static readonly type = `${STATE_NAME} Destroy View`;
}

export class InitFromStorage {
  static readonly type = `${STATE_NAME} Init From Storage`;
}

export class InitFromLink {
  static readonly type = `${STATE_NAME} Init From Link`;
}

export class CopyShareLink {
  static readonly type = `${STATE_NAME} Copy Share Link`;
}

export class AddVariant {
  static readonly type = `${STATE_NAME} Add Variant`;
  constructor(
    public projectId: string,
    public subsystemCode: SubsystemCode,
    public generationTaskId: string,
    public modelExternalId: string,
  ) {}
}

export class RemoveTask {
  static readonly type = `${STATE_NAME} Remove Task`;
  constructor(
    public projectId: string,
    public subsystemCode: SubsystemCode,
    public generationTaskId: string,
  ) {}
}

export class RemoveModel {
  static readonly type = `${STATE_NAME} Remove Model`;
  constructor(
    public projectId: string,
    public subsystemCode: SubsystemCode,
    public generationTaskId: string,
    public modelExternalId: string,
  ) {}
}

export class ClearList {
  static readonly type = `${STATE_NAME} Clear List`;
  constructor(
    public projectId: string,
    public subsystemCode: SubsystemCode,
  ) {}
}

export class ClearAll {
  static readonly type = `${STATE_NAME} Clear All`;
}
