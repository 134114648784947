<ng-container *tuiLet="subsystemShellRoot$ | async as root">
  @if (backLink$ | async; as link) {
    <a automation-id="breadcrumbs_backward" class="icon-link" [routerLink]="link" [relativeTo]="root">
      <tui-icon class="icon-link__icon" [icon]="'@tui.arrow-left'" />
    </a>
  }

  @if (mapLinkData$ | async; as mapLinkData) {
    <a
      automation-id="breadcrumbs_map-link"
      class="icon-link"
      [routerLink]="mapLinkData.link"
      [queryParams]="mapLinkData.params"
      [relativeTo]="root"
    >
      <tui-icon class="icon-link__icon" [icon]="'@tui.map'" />
    </a>
  }

  <div class="caption">
    <div automation-id="breadcrumbs_page-title" class="title">{{ title }}</div>
    <project-breadcrumbs class="project-breadcrumbs" />
  </div>

  <tui-scrollbar class="subsystem-breadcrumbs-wrapper">
    <div class="subsystem-breadcrumbs">
      @for (crumb of breadcrumbsData$ | async; track crumb.subsystem) {
        <subsystem-breadcrumbs-link [breadcrumbsLink]="crumb" [relativeTo]="root" />
      }
    </div>
  </tui-scrollbar>

  @if (rightTemplate$ | async; as rightTemplate) {
    <div class="right-content">
      <ng-template *ngTemplateOutlet="rightTemplate"></ng-template>
    </div>
  }
</ng-container>
