import {NgOptimizedImage} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {TuiButton} from '@taiga-ui/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'read-only-page',
  standalone: true,
  imports: [TuiButton, NgOptimizedImage],
  templateUrl: './read-only-page.component.html',
  styleUrl: './read-only-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadOnlyPageComponent {
  private readonly oidcSecurityService = inject(OidcSecurityService);

  logOut() {
    /** Можно не отписываться, потому что пользователя выкинет в ауф */
    this.oidcSecurityService.logoff().subscribe();
  }
}
